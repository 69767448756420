<template>
  <vs-dropdown v-if="!IsQrCode" vs-custom-content vs-trigger-click class="cursor-pointer">
    <span class="cursor-pointer flex items-center i18n-locale">
      <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" />
      <span class="hidden sm:block ml-2">{{ getCurrentLocaleData.lang }}</span>
    </span>
    <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
      <vs-dropdown-item @click="updateLocale('en')">
        <img  class="h-2 w-2 mr-1" src="@/assets/images/flags/en.png" alt="en" />
        &nbsp;{{ $t("English") }}
      </vs-dropdown-item>
      <!-- <vs-dropdown-item @click="updateLocale('fr')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/fr.png" alt="fr" />
        &nbsp;French
      </vs-dropdown-item> -->
      <vs-dropdown-item @click="updateLocale('ar')">
        <img  class="h-2 w-2 mr-1" src="@/assets/images/flags/ar.png" alt="ar" />
        &nbsp;{{$t('Arabic')}}
      </vs-dropdown-item>
      <!-- <vs-dropdown-item @click="updateLocale('ur')">
        <img  class="h-4 w-5 mr-1" src="@/assets/images/flags/ur.png" alt="pt" />
        &nbsp;{{ $t("Urdu") }}
      </vs-dropdown-item> -->
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
/* eslint-disable */
export default {
  data(){
    return{
    IsQrCode: false,
    }
  },
  computed: {
    i18n_locale_img() {
      return require(`@/assets/images/flags/${this.$i18n.locale}.png`);
    },
    rtl: {
      get() {
        return this.$vs.rtl;
      },
      set(val) {
        this.$vs.rtl = val;
      }
    },
    getCurrentLocaleData() {
      const locale = this.$i18n.locale;
      if (locale === "en") return { flag: "us", lang: "English" };
      // else if (locale === "fr") return { flag: "fr", lang: "French" };
      else if (locale === "ar") return { flag: "ar", lang: "عربي" };
     else if (locale === "ur") return { flag: "ur", lang: "اردو" };

    }
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("SaveLang", locale);
      if (locale === "ar") {
        this.SwitchToRTL();
        // location.reload("/");
      } else {
        this.SwitchToLTR();
        // location.reload("/");
      }
    },
    SwitchToRTL() {
      this.rtl = true;
    },
    SwitchToLTR() {
      this.rtl = false;
    }
  },
  created(){
    debugger;
    var x = this.$route.name;
    this.IsQrCode = x == "PickupQRCode"? true : false;
  }
};
</script>
